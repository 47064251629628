import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"

import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import Projects from "../components/Sections/Projects/Projects"
import "./home.scss"

const IndexPage = () => {
  const { contentfulPerson } = useStaticQuery(graphql`
    query {
      contentfulPerson {
        name
        title
        email
        github
        linkedIn
        twitter
        intro {
          intro
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Home" />
      <section className="subheader animated fadeIn">
        <div className="row wrapper">
          <div className="content col-md-6 animated fadeIn">
            <h2>{contentfulPerson.name}</h2>
            <h4>{contentfulPerson.title}</h4>
            <ReactMarkdown source={contentfulPerson.intro.intro} />
            <div className="links">
              {/* <a href="/portfolio" className="icon ion-ios-arrow-forward">
                Portfolio
              </a> */}
              {/* <a href="/about" className="icon ion-ios-arrow-forward">
                More about me
              </a> */}
              <a href="/contact" className="icon ion-ios-arrow-forward">
                Get in touch
              </a>
            </div>
          </div>
          <div className="col-md-6"></div>
        </div>
      </section>
      <Projects sectionSubtitle columns={6} />
    </Layout>
  )
}

export default IndexPage
