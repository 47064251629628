import React from "react"
import classNames from "classnames"
import "./Projects.scss"
import { Link, useStaticQuery, graphql } from "gatsby"

const SectionProjects = ({ sectionTitle, sectionSubtitle, columns, ...props }) => {
  const { allContentfulProject } = useStaticQuery(graphql`
    query {
      allContentfulProject(filter: {node_locale: {eq: "en-US"}}, sort: { order: DESC, fields: publishDate }, limit: 4) {
        edges {
          node {
            title
            slug
            subtitle
            tags
            projectThumbnail {
              file {
                url
              }
              title
            }
          }
        }
      }
    }
  `)
  const projects = allContentfulProject.edges
  return (
    <section className="projects">
      <div className="wrapper">
        <div className="title animated fadeInDown">
          <h2>{sectionTitle ? sectionTitle : "Portfolio"}</h2>
          <h3 className="subtitle">{sectionSubtitle ? "Selection of a few of my projects" : null}</h3>
        </div>
        <div className={classNames("row", props.alignTop && "alignTop")}>
          {projects.map(({ node }) => {
            return (
              <div className={`col-md-${columns}`}>
                <Link className="project animated fadeInDown" to={`portfolio/${node.slug}`}>
                  {node.projectThumbnail && <img src={node.projectThumbnail.file.url} alt={node.projectThumbnail.file.title}/>}
                  <h4 className="icon ion-ios-arrow-forward">
                  <span>{node.title}</span>
                  <small>{node.subtitle}</small></h4>
                </Link>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default SectionProjects
